<template>
  <div>
    <v-row class="justify-center align-center">
      <v-col
        cols="12"
        md="3"
        class="d-flex justify-center align-center"
        style="height: 100vh"
      >
        <v-row class="py-10 px-5">
          <v-col cols="12">
            <v-card class="mx-auto my-2 px-5 py-5" outlined style="width: 92%">
              <v-img :src="logo" class="mx-auto" style="max-width: 175px">
              </v-img>
              <div class="div-circle text-center">
                <i class="fas fa-lock"></i>
              </div>
              <h4 class="text-center my-2">Problemas para entrar?</h4>
              <p class="text-center" style="font-size: small">
                Insira o seu email, telefone ou nome de usuário e enviaremos um
                link para você voltar a acessar a sua conta.
              </p>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="E-mail"
                  required
                ></v-text-field>

                <v-btn block color="secondary" elevation="2" class="mt-5"
                  >Enviar link para login</v-btn
                >

                <v-row class="my-2 justify-center align-center">
                  <v-col>
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="3"> OU </v-col>
                  <v-col>
                    <v-divider></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" class="text-center">
                    <router-link to="/signup" custom
                      >Criar nova conta</router-link
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-card class="mx-auto px-2 py-2" outlined style="width: 92%">
              Lembrei: <router-link to="/login" custom>Voltar</router-link>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <Footer />
  </div>
</template>
<script>
export default {
  data() {
    return {
      logo: require("@/assets/logo.png"),
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },
};
</script>
<style>
.div-circle {
  width: 50px;
  height: 50px;
  border: 2px #000 solid;
  border-top-left-radius: 100% !important;
  border-top-right-radius: 100% !important;
  border-radius: 100%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
</style>